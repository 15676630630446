<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <div class="card login">
            <h1>LuniversoTuo</h1>
            <form class="form-group">
              <input v-model="input.username" type="text" class="form-control" placeholder="Utente" required>
              <input v-model="input.password" type="password" class="form-control" placeholder="Password" required>
              <button class="btn btn-primary btn-lg" type="button" v-on:click="login()">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";

export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: "",
        password: ""
      }
    }
  },
  methods: {
    login() {
      let self = this
      axios.post('https://admin.luniversotuo.it/rest/login',{
        email: this.input.username,
        password: this.input.password
      })
      .then(function (response){
        if(response.status == 200){
          VueCookies.set('token' , response.data.token, "7d")
          self.$router.push('/cercautenti/')
        }else{
          alert("Errore di autenticazione")
        }
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>