<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-lg-9">
          <div class="row">

            <div class="col-md-6 col-sm-6 col-xs-6">
              <div class="nome"><span class="bold">{{ utente.DES_NOME }}</span></div>
              <div class="cognome"><span class="bold">{{ utente.DES_COGNOME }}</span></div>
              <div class="cf">{{ utente.COD_CF_PIVA }}</div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
              <div class="cf tipo">Stato civile: {{ utente.DES_TIPO_ANAGRAFE }}</div>
              <div class="cf tipo">Figli: {{ utente.DES_TIPO_FIGLI }}</div>
              <div class="sesso">Sesso: {{ utente.DES_SESSO }}</div>
              <div class="ditta">Ditta: <span v-show="utente.FLG_DITTA==0 || utente.FLG_DITTA=='N'">No</span><span
                  v-show="utente.FLG_DITTA==1 || utente.FLG_DITTA=='S'">Si</span></div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-md-12">
              <h4>Consegna Pacco</h4>
              <b-button v-on:click="consegnaPacco()" variant="primary">
                <img src="/img/plus.png" style="max-height: 20px">
              </b-button>
            </div>
          </div>

          <!--<hr>

          <div class="row">
            <div class="col-md-12">
              <h4>Pacchi <small>({{ pacchi.length }})</small></h4>
              <b-button v-b-toggle.pacchi variant="primary">
                <img src="/img/plus.png" style="max-height: 20px">
              </b-button>
            </div>
            <b-collapse id="pacchi" class="mt-2">
              <div v-if="pacchi.length == 0" class="card-text">Nessun pacco</div>
              <div class="pacco" v-for="pacco in pacchi" :key="pacco.COD_PACCO">
                <hr>
                <h5 class="pacco" style="text-align: left;margin-left:20px;">Campagna: {{pacco.DES_SUB_CAMPAGNA}}<br>
                  <strong>Priorità: {{pacco.DES_PRIORITA_PACCO}}</strong><br>
                  Consegna: {{pacco.DES_CONSEGNA_PACCO}}</h5>
                <h6 v-if="pacco.DES_NOTE">Note</h6>
                <span>{{pacco.DES_NOTE}}</span>
                <button class="btn btn-primary btn-lg" v-on:click="avanzaPacco(pacco.COD_PACCO)">Avanza</button>
              </div>
            </b-collapse>
          </div>

          <hr>

          <div class="row">
            <div class="col-md-12">
              <h4>Appuntamenti <small>({{ appuntamenti.length }})</small></h4>
              <b-button v-b-toggle.appuntamenti variant="primary"><i class="fa fa-compress" aria-hidden="true"></i>
              </b-button>
            </div>
            <b-collapse id="appuntamenti" class="mt-2">
                <div v-if="appuntamenti.length == 0" class="card-text">Nessun appuntamento</div>
                <div class="appuntamento" v-for="appuntamento in appuntamenti" :key="appuntamento.COD_APPUNTAMENTO">
                  <hr>
                  <h5 class="agente-appuntamento">{{appuntamento.COD_USERID_VENDITORE1}}</h5>
                  <span>{{appuntamento.DAT_DATA_APPUNTAMENTO | moment("DD-MM-YYYY")}} - {{appuntamento.DAT_ORA_APPUNTAMENTO | moment("HH:mm")}}</span>
                  <div>Tipo: {{appuntamento.DES_TIPO_APPUNTAMENTO}}</div>
                  <div>Note: {{appuntamento.DES_NOTE}}</div>
                  <button class="btn btn-primary btn-lg" v-on:click="avanzaAppuntamento(appuntamento.COD_APPUNTAMENTO)">Avanza</button>
                </div>
            </b-collapse>
          </div>

          <hr>

          <div class="row">
            <div class="col-md-12">
              <h4>Telefonate <small>({{ telefonate.length }})</small></h4>
              <b-button v-b-toggle.telefonate variant="primary"><i class="fa fa-compress" aria-hidden="true"></i>
              </b-button>
            </div>
            <b-collapse id="telefonate" class="mt-2">
                <div v-if="telefonate.length == 0" class="card-text">Nessuna telefonata</div>
                <div class="telefonate" v-for="telefonata in telefonate" :key="telefonata.COD_TELEFONATA">
                  <hr>
                  <h5 class="agente-telefonata">{{telefonata.COD_USERID_VEND_ASSEGNATO}} - {{telefonata.COD_USERID_TELEFONISTA}}</h5>
                  <span>{{telefonata.DAT_DATA_PREV | moment("DD-MM-YYYY")}} - {{telefonata.ORA_APPUNTAMENTO_TEL | moment("HH:mm")}}</span>
                  <div>Note: {{telefonata.DES_NOTE}}</div>
                  <button class="btn btn-primary btn-lg" v-on:click="avanzaTelefonata(telefonata.COD_TELEFONATA)">Avanza</button>
                </div>
            </b-collapse>
          </div>
          -->
          <hr>

          <div class="row">
            <div class="col-md-12">
              <h2 style="margin-bottom: 19px;display: inline-block;">Dati Anagrafici</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>Data Nascita</label>
              <span class="data-nascita">{{ utente.DAT_NASCITA }}</span>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>Telefono cellulare</label>
              <span class="tel">{{ utente.CELLULARE }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>Comune Nascita</label>
              <span class="luogo-nascita">{{ utente.DES_COMUNE_NASCITA }}</span>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>Telefono ufficio</label>
              <span class="tel" v-if="utente.UFFICIO != null">{{ utente.UFFICIO }}</span>
              <span class="tel" v-if="utente.UFFICIO == null">{{ utente.DES_NUMERO_TELEFONO }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>Indirizzo</label>
              <span
                  class="indirizzo">{{ utente.COD_STRADA }} {{ utente.DES_INDIRIZZO }} {{ utente.DES_NUMERO_CIVICO }}</span>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>Fax</label>
              <span class="tel">{{ utente.FAX }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>CAP</label>
              <span class="localita">{{ utente.DES_CAP }}</span>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>Telefono fisso</label>
              <span class="tel" v-if="utente.TELEFONO_FISSO != null">{{ utente.TELEFONO_FISSO }}</span>
              <span class="tel" v-if="utente.TELEFONO_FISSO == null">{{ utente.DES_NUMERO_TELEFONO_2 }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>Comune residenza</label>
              <span class="email">{{ utente.DES_COMUNE }}</span>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>Professione</label>
              <span class="professione">{{ utente.professione }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <label>Località residenza</label>
              <span class="localita">{{ utente.DES_LOCALITA }}</span>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>Email</label>
              <span class="email">{{ utente.DES_EMAIL }}</span>
            </div>
          </div>
          <hr>

          <div class="row" v-show="familiari.length == 0">
            <div class="col-md-12">
              Nessun familiare
            </div>
          </div>
          <div class="row" v-show="familiari.length > 0">
            <div class="col-md-12">
              <h2 style="margin-bottom: 19px;">Collegamenti</h2>
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Nome</th>
                  <th>Azioni</th>
                </tr>
                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
          <hr>
        </div>
        <div class="col-md-3">
          <hr class="visible-xs visible-sm">
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-primary btn-lg" v-on:click="addAppuntamento()" style="margin-bottom: 10px">
                Aggiungi appuntamento
              </button>
              <button class="btn btn-primary btn-lg" v-on:click="addTelefonata()" style="margin-bottom: 10px">
                Aggiungi telefonata
              </button>
              <button class="btn btn-primary btn-lg" v-on:click="addPacco()" style="margin-bottom: 10px">
                Aggiungi pacco
              </button>
            </div>
          </div>
          <hr>

        </div>
      </div>
    </div>

    <b-modal id="aggiungi_pacco">
      <template #modal-title>
        Consegna Pacco
      </template>
      <div class="d-block text-center">
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            Campagna
            <b-form-select v-model="pacco_add.campagna">
              <option v-for="campagna in campagne" v-bind:value="campagna" v-bind:key="campagna.COD_SUB_CAMPAGNA">
                {{ campagna.DES_CAMPAGNA }} -  {{ campagna.DES_SUB_CAMPAGNA }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            Stato Pacco
            <b-form-select v-model="pacco_add.stato_pacco">
              <option v-for="stato_pacco in stati_pacchi" v-bind:value="stato_pacco" v-bind:key="stato_pacco.COD_STATO_PACCO">
                {{ stato_pacco.DES_STATO_PACCO }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            Data Consegna
            <input v-model="pacco_add.data" type="date" class="form-control" placeholder="Data Consegna" required>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            Tipologia Invio
            <b-form-select v-model="pacco_add.tipologia">
              <option v-for="tipo_pacco in tipi_pacco" v-bind:value="tipo_pacco" v-bind:key="tipo_pacco.ID_CONSEGNA_PACCO">
                {{ tipo_pacco.DES_CONSEGNA_PACCO }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            Note Pacco
            <textarea v-model="pacco_add.note_pacco" class="form-control" placeholder="Note Pacco"></textarea>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-12">
            <b-form-group label="Azione Successiva">
              <b-form-radio v-model="pacco_add.azione_successiva" value="chiamata">Telefonata</b-form-radio>
              <b-form-radio v-model="pacco_add.azione_successiva" value="appuntamento">Appuntamento</b-form-radio>
            </b-form-group>
          </div>
        </div>
      </div>
      <!--<input type="hidden" :value="ELISABETTA" v-model="pacco_add.telefonista">-->
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='chiamata'">
        <div class="col-12">
          Data Telefonata
          <input v-model="pacco_add.data_telefonata" type="date" class="form-control" placeholder="Data Telefonata">
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='chiamata'">
        <div class="col-12">
          Ora Telefonata
          <input v-model="pacco_add.ora_telefonata" type="time" class="form-control" placeholder="Ora Telefonata">
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='chiamata'">
        <div class="col-12">
          Note Telefonata
          <textarea v-model="pacco_add.note_telefonata" class="form-control" placeholder="Note Telefonata"></textarea>
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='appuntamento'">
        <div class="col-12">
          Venditore
          <b-form-select v-model="pacco_add.venditore">
            <option value="GIANCA">GIANCARLO</option>
            <option value="FRANCESCO">FRANCESCO</option>
          </b-form-select>
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='appuntamento'">
        <div class="col-12">
          Affiancamento
          <b-form-select v-model="pacco_add.venditore_affiancamento">
            <option value="EMPTY"></option>
            <option value="GIANCA">GIANCARLO</option>
            <option value="FRANCESCO">FRANCESCO</option>
          </b-form-select>
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='appuntamento'">
        <div class="col-12">
          <FullCalendar :options="calendarOptions" :is24hr="format24h" />
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='appuntamento'">
        <div class="col-12">
          Data Appuntamento
          <input v-model="pacco_add.data_appuntamento" type="date" class="form-control" placeholder="Data Appuntamento">
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='appuntamento'">
        <div class="col-12">
          Ora Appuntamento
          <input v-model="pacco_add.ora_appuntamento" type="time" class="form-control" placeholder="Ora Telefonata">
        </div>
      </div>
      <div class="row" style="margin-top: 10px" v-show="pacco_add.azione_successiva=='appuntamento'">
        <div class="col-12">
          Note Appuntamento
          <textarea v-model="pacco_add.note_appuntamento" class="form-control" placeholder="Note Appuntamento"></textarea>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button @click="$bvModal.hide('aggiungi_pacco')">Chiudi</b-button>
          <button class="btn btn-primary" type="button" v-on:click="salva()">Salva</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import momentPlugin from '@fullcalendar/moment'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar';

export default {
  name: 'Utente',
  components: {
    FullCalendar
  },
  data() {
    return {
      format24h: true,
      calendarOptions: {
        plugins: [ googleCalendarPlugin, dayGridPlugin, interactionPlugin, momentPlugin],
        height: 300,
        titleFormat: 'DD/MM/YYYY',
        timeFormat: 'H(:mm)',
        initialView: 'dayGridDay',
        lang: "it",
        timezone: "local",
        googleCalendarApiKey: "AIzaSyA8kStwWm2Q860-frqZK4l1r0lq3dFoLrw",
        eventSources: [
          {
            googleCalendarId: "luniversotuo.it_3iv6hejqe7glspvk4au7f77460@group.calendar.google.com"
          }
        ],
      },
      utente: {
        DES_NOME: "",
        DES_COGNOME: "",
        COD_ANAGRAFE: "",
        COD_CAP_GENERICO: "",
        COD_COMUNE: "",
        COD_FASCIA_ETA: "",
        COD_PROVINCIA: "",
        COD_STRADA: "",
        DAT_AGGIORNAMENTO: "",
        DAT_INSERIMENTO: "",
        DES_ALL_TEXT: "",
        DES_CAP: "",
        DES_COMUNE: "",
        DES_INDIRIZZO: "",
        DES_NUMERO_CIVICO: "",
        DES_NUMERO_TELEFONO: "",
        DES_TELFONO: "",
        FLG_DITTA: ""
      },
      pacco_add:{
        stato_pacco:'',
        data: '',
        azione_successiva: ''
      },
      familiari: [],
      appuntamenti: [],
      telefonate: [],
      pacchi: [],
      stati_pacchi: [],
      campagne: [],
      tipi_pacco: []
    }
  },
  methods: {
    getUser() {
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/users/user/' + this.$route.params.id)
          .then(response => {
            console.log(response)
            self.utente = response.data
          })
    },
    getRelazioni() {
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/users/user/' + this.$route.params.id + "/relation")
          .then(response => {
            console.log(response)
            self.familiari = response.data
          })
    },
    getAppuntamenti() {
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/appuntamenti/lista_utenti/' + this.$route.params.id)
          .then(response => {
            console.log(response)
            self.appuntamenti = response.data
          })
    },
    getTelefonate() {
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/chiamate/lista_utenti/' + this.$route.params.id)
          .then(response => {
            console.log(response)
            self.telefonate = response.data
          })
    },
    getCampagne(){
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/campagne')
          .then(response => {
            console.log(response)
            self.campagne = response.data
          })
    },
    getPacchi() {
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/users/user/' + this.$route.params.id + "/pacco")
          .then(response => {
            console.log(response)
            self.pacchi = response.data
          })
    },
    addAppuntamento() {

    },
    addTelefonata() {

    },
    addPacco() {

    },
    getStatiPacco(){
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/pacchi/stato')
          .then(response => {
            console.log(response)
            self.stati_pacchi = response.data
          })
    },
    getTipoPacco(){
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.get('https://admin.luniversotuo.it/rest/pacchi/tipi_sub')
          .then(response => {
            console.log(response)
            self.tipi_pacco = response.data
          })
    },
    consegnaPacco() {
      this.$bvModal.show("aggiungi_pacco")
    },
    checkCampi(){
      if(this.pacco_add.campagna === null || this.pacco_add.campagna === undefined){
        alert("Seleziona la campagna");
        return false;
      }else if(this.pacco_add.stato_pacco === null || this.pacco_add.stato_pacco === undefined){
        alert("Seleziona lo stato del pacco");
        return false;
      }else if(this.pacco_add.data === null || this.pacco_add.data === undefined){
        alert("Seleziona la data di consegna del pacco");
        return false;
      }else if(this.pacco_add.tipologia === null || this.pacco_add.tipologia === undefined){
        alert("Seleziona la tipologia di invio");
        return false;
      }else if(this.pacco_add.azione_successiva === null || this.pacco_add.azione_successiva === undefined){
        alert("Seleziona un azione successiva");
        return false;
      }

      return true;
    },
    salva(){
      if( this.checkCampi() ){
        if(this.pacco_add.azione_successiva == "chiamata"){
          /*if(this.pacco_add.telefonista === null || this.pacco_add.telefonista === undefined){
            alert("Seleziona la telefonista");
          }else */if(this.pacco_add.data_telefonata === null || this.pacco_add.data_telefonata === undefined){
            alert("Seleziona la data della telefonata");
          }else if(this.pacco_add.ora_telefonata === null || this.pacco_add.ora_telefonata === undefined){
            alert("Seleziona l'ora della telefonata");
          }else{
            console.log("inserisco i dati - telefonata")
            axios.defaults.headers.common = {'token': VueCookies.get("token")}
            axios.post('https://admin.luniversotuo.it/rest/pacchi/insert', {
              "COD_ANAGRAFE":this.$route.params.id,
              "COD_ESITO_PACCO":1,
              "COD_SUB_CAMPAGNA":this.pacco_add.campagna.COD_SUB_CAMPAGNA,
              "DAT_DATA_PACCO":this.pacco_add.data,
              "DAT_ORA_PACCO":"09:00",
              "DES_NOTE":this.pacco_add.note_pacco,
              "ID_CONSEGNA_PACCO":this.pacco_add.tipologia.ID_CONSEGNA_PACCO,
              "ID_PRIORITA_PACCO":0
            }).then(response => {
              console.log(response)
              axios.post('https://admin.luniversotuo.it/rest/pacchi/avanza', {
                "COD_PACCO":response.data.COD_PACCO,
                "COD_ESITO_PACCO": this.pacco_add.stato_pacco.COD_STATO_PACCO,
                "DAT_DATA_PACCO":this.pacco_add.data,
                "DAT_ORA_PACCO":"09:00",
                "azione_successiva":"telefonata",
                "NUM_PROGR_STATO":1,
                "COD_CONTATTO":response.data.COD_CONTATTO[0],
                "COD_SUB_CAMPAGNA":this.pacco_add.campagna.COD_SUB_CAMPAGNA,
                "telefonata":{
                  "COD_ANAGRAFE":this.$route.params.id,
                  "COD_CONTATTO":response.data.COD_CONTATTO[0],
                  "COD_STATO_TELEFONATA":1,
                  "COD_SUB_CAMPAGNA":this.pacco_add.campagna.COD_SUB_CAMPAGNA,
                  "COD_USERID_TELEFONISTA":"FRONTOFF",
                  "DES_NOTE":this.pacco_add.note_telefonata,
                  "NUM_PROGR_STATO":1,
                }
              }).then(response2 => {
                console.log(response2)
                if(response2.status == 200){
                  alert("Chiamata inserita correttamente");
                  this.$bvModal.hide("aggiungi_pacco");
                  this.pacco_add = {
                    stato_pacco:'',
                    data: '',
                    azione_successiva: ''
                  };
                }else{
                  alert("Errore di inserimento chiamata");
                }
              })
            })

          }
        }else if(this.pacco_add.azione_successiva == "appuntamento"){
          if(this.pacco_add.venditore === null || this.pacco_add.venditore === undefined){
            alert("Seleziona il venditore");
          }else if(this.pacco_add.data_appuntamento === null || this.pacco_add.data_appuntamento === undefined){
            alert("Seleziona la data dell'appuntamento");
          }else if(this.pacco_add.ora_appuntamento === null || this.pacco_add.ora_appuntamento === undefined){
            alert("Seleziona l'ora dell'appuntamento");
          }else{
            console.log("inserisco i dati - appuntamento")

            axios.defaults.headers.common = {'token': VueCookies.get("token")}
            axios.post('https://admin.luniversotuo.it/rest/pacchi/insert', {
              "COD_ANAGRAFE":this.$route.params.id,
              "COD_ESITO_PACCO":1,
              "COD_SUB_CAMPAGNA":this.pacco_add.campagna.COD_SUB_CAMPAGNA,
              "DAT_DATA_PACCO":this.pacco_add.data,
              "DAT_ORA_PACCO":"09:00",
              "DES_NOTE":this.pacco_add.note_pacco,
              "ID_CONSEGNA_PACCO":this.pacco_add.tipologia.ID_CONSEGNA_PACCO,
              "ID_PRIORITA_PACCO":0
            }).then(response => {
              console.log(response)
              axios.post('https://admin.luniversotuo.it/rest/pacchi/avanza', {
                "COD_PACCO":response.data.COD_PACCO,
                "COD_ESITO_PACCO": this.pacco_add.stato_pacco.COD_STATO_PACCO,
                "DAT_DATA_PACCO":this.pacco_add.data,
                "DAT_ORA_PACCO":"09:00",
                "azione_successiva":"appuntamento",
                "NUM_PROGR_STATO":1,
                "COD_CONTATTO":response.data.COD_CONTATTO[0],
                "COD_SUB_CAMPAGNA":this.pacco_add.campagna.COD_SUB_CAMPAGNA,
                "appuntamento":{
                  "COD_ANAGRAFE":this.$route.params.id,
                  "COD_CONTATTO":response.data.COD_CONTATTO[0],
                  "DATA_APPUNTAMENTO":this.pacco_add.data_appuntamento,
                  "ORA_APPUNTAMENTO":this.pacco_add.ora_appuntamento,
                  "COD_USERID_VENDITORE1":this.pacco_add.venditore,
                  "DES_NOTE":this.pacco_add.note_appuntamento,
                  "NUM_PROGR_STATO":1,
                  "COD_ESITO_APPUNTAMENTO":1,
                  "NUM_DURATA":120,
                  "COD_SUB_CAMPAGNA":this.pacco_add.campagna.COD_SUB_CAMPAGNA,
                }
              }).then(response2 => {
                console.log(response2)
                if(response2.status == 200){
                  alert("Appuntamento inserito correttamente");
                  this.$bvModal.hide("aggiungi_pacco");
                  this.pacco_add = {
                    stato_pacco:'',
                    data: '',
                    azione_successiva: ''
                  };
                }else{
                  alert("Errore di inserimento appuntamento");
                }
              })
            })
          }
        }
      }
    }
  },
  created() {
    this.getUser()
    this.getRelazioni()
    this.getAppuntamenti()
    this.getPacchi()
    this.getTelefonate()
    this.getStatiPacco()
    this.getTipoPacco()
    this.getCampagne()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-weight: bold;
  margin-right: 10px;
}
</style>