import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import CercaUtenti from '../views/CercaUtenti.vue'
import Utente from '../views/Utente.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/cercautenti',
    name: 'CercaUtenti',
    component: CercaUtenti
  },
  {
    path: '/utente/:id',
    name: 'Utente',
    component: Utente
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
