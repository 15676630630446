import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import './registerServiceWorker'
import { BootstrapVue } from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueGoogleCalendar from "vue-google-calendar"


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import router from './router'

Vue.config.productionTip = false
Vue.config.access_token = ''
Vue.use(BootstrapVue)
Vue.use(VueCookies)
Vue.use(VueMoment, {
  moment,
})
Vue.use(VueGoogleCalendar)

new Vue({
  router,
  render: h => h(App),
  created() {
    var scripts = [
      "https://admin.luniversotuo.it/bower_components/codice-fiscale/codice.fiscale.min.js",
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  }
}).$mount('#app')
