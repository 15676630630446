<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Cerca</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
          <form class="form-group">
            <input v-model="input.cognome" type="text" class="form-control" placeholder="Cognome">
          </form>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
          <form class="form-group">
            <input v-model="input.nome" type="text" class="form-control" placeholder="Nome">
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
          <form class="form-group">
            <button class="btn btn-primary btn-lg" type="button" v-on:click="cerca()">Cerca</button>
            <button class="btn btn-secondary btn-lg" type="button" v-on:click="aggiungi()">Aggiungi Persona</button>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
          <ul class="list-group">
            <li class="list-group-item" v-on:click="view(item.COD_ANAGRAFE)" v-for="item in items" :key="item.id">
              {{ item.DES_COGNOME }} {{ item.DES_NOME }}<br><small>{{ item.COD_STRADA }} {{ item.DES_INDIRIZZO }}
              {{ item.DES_NUMERO_CIVICO }}</small></li>
          </ul>
        </div>
      </div>
    </div>

    <b-modal id="aggiungi_persona">
      <template #modal-title>
        Aggiungi Persona
      </template>
      <div class="d-block text-center">
        <div class="row" style="margin-top: 10px">
          <div class="col-6">
            <input v-model="input_add.nome" type="text" class="form-control" placeholder="Nome" required>
          </div>
          <div class="col-6">
            <input v-model="input_add.cognome" type="text" class="form-control" placeholder="Cognome" required>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-6">
            <!-- luogo nascita -->
            <label>Provincia di nascita</label>
            <b-form-select v-model="provincia_selezionata" @change="cambiaLuogoNascita()">
              <option v-for="provincia in province" v-bind:value="provincia" v-bind:key="provincia" >
                {{ provincia }}
              </option>
            </b-form-select>
          </div>
          <div class="col-6">
            <!-- luogo nascita -->
            <label>Luogo di nascita</label>
            <b-form-select v-model="input_add.luogo_nascita">
              <option v-for="comune in comuni_selezionati" v-bind:value="comune" v-bind:key="comune.COD_COMUNE">
                {{ comune.DES_COMUNE }}
              </option>
            </b-form-select>
          </div>
          <div class="col-6">
            <!-- sesso -->
            <b-form-group label="Sesso">
              <b-form-radio v-model="input_add.sesso" value="M">M</b-form-radio>
              <b-form-radio v-model="input_add.sesso" value="F">F</b-form-radio>
            </b-form-group>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-6">
            <!-- data nascita -->
            <input v-model="input_add.data_nascita" type="date" class="form-control" placeholder="Data Nascita"
                   required>
          </div>
          <div class="col-6">
            <!-- codice fiscale -->
            <input v-model="input_add.codice_fiscale" type="text" class="form-control" placeholder="Codice Fiscale"
                   required>
            <button class="btn" v-on:click="calcoloCodiceFiscale()">Calcola</button>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button @click="$bvModal.hide('aggiungi_persona')">Chiudi</b-button>
          <button class="btn btn-primary" type="button" v-on:click="salva()">Salva</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import moment from 'moment-timezone'

export default {
  name: 'CercaUtenti',
  data() {
    return {
      input_add: {
        nome: "",
        cognome: "",
        sesso: "",
        data_nascita: "",
        codice_fiscale: "",
        luogo_nascita: {}
      },
      input: {
        cognome: "",
        nome: ""
      },
      items: [],
      comuni: [],
      comuni_selezionati: [],
      province: [],
      provincia_selezionata:''
    }
  },
  methods: {
    cerca() {
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      let self = this
      axios.post('https://admin.luniversotuo.it/rest/search/utenti_simple', {
        cognome: this.input.cognome,
        nome: this.input.nome,
      })
          .then(function (response) {
            if (response.status == 200) {
              self.items = response.data
            } else {
              alert("Nessun risultato")
            }
          })
    },
    cambiaLuogoNascita(){
      this.comuni_selezionati = [];
        for(var i=0;i<this.comuni.length;i++){
          if(this.comuni[i].COD_PROVINCIA == this.provincia_selezionata ){
            console.log(this.comuni[i]);
            this.comuni_selezionati.push(this.comuni[i]);
          }
        }
    },
    aggiungi() {
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      let self = this
      axios.get('https://admin.luniversotuo.it/rest/search/comuni')
          .then(function (response) {
            self.comuni = response.data
            for(var i=0;i<self.comuni.length; i++){
              self.province.push(self.comuni[i].COD_PROVINCIA);
            }
            self.province = self.province.filter(function (x, i, a) {
              return a.indexOf(x) == i;
            });
            self.province = self.province.sort(function(a, b){
              if(a < b) { return -1; }
              if(a > b) { return 1; }
              return 0;
            })
            self.$bvModal.show("aggiungi_persona")
          })
    },
    salva() {
      let self = this
      axios.defaults.headers.common = {'token': VueCookies.get("token")}
      axios.post('https://admin.luniversotuo.it/rest/users/user_add', {
        COD_CF_PIVA: this.input_add.codice_fiscale,
        COD_COMUNE_NASCITA: this.input_add.luogo_nascita.COD_COMUNE_NASCITA,
        DAT_NASCITA: this.input_add.data_nascita,
        DAT_PRIVACY: null,
        DAT_PRIVACY_COMPAGNIA: null,
        DAT_PRIVACY_WEB: null,
        DES_COGNOME: this.input_add.cognome,
        DES_NOME: this.input_add.nome,
        DES_SESSO: this.input_add.sesso,
        FLG_DITTA: 0
      })
          .then(response => {
            console.log(response)
            self.$bvModal.hide("aggiungi_persona")
            alert("Utente inserito correttamente")
          }).catch(error => {
            console.log(error.response)
            alert(error.response.data)
      })
    },
    view(id) {
      this.$router.push('/utente/' + id)
    },
    calcoloCodiceFiscale(){
      // eslint-disable-next-line no-undef
      var cf = CodiceFiscale.compute({
        name: this.input_add.nome,
        surname: this.input_add.cognome,
        gender: this.input_add.sesso,
        day: moment(this.input_add.data_nascita).format('DD'),
        month: moment(this.input_add.data_nascita).format('MM'),
        year: moment(this.input_add.data_nascita).format('YYYY'),
        birthplace: this.input_add.luogo_nascita.DES_COMUNE,
        birthplaceProvincia: this.input_add.luogo_nascita.COD_PROVINCIA});
      this.input_add.codice_fiscale=cf
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>